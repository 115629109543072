import Vue from "vue";
import VueRouter from "vue-router";
import Map from "../views/Map.vue";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: {
        name: "map",
      },
    },
    {
      path: "/map",
      name: "map",
      component: Map,
    },
    {
      path: "/control",
      name: "control",
      component: () => import("../views/Control.vue"),
    },
    {
      path: "/dico-alerts",
      name: "dico-alerts",
      component: () => import("../views/DicoAlerts.vue"),
    },
  ],
});

export default router;

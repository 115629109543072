<template>
  <span class="loader"></span>
</template>

<script>
export default {
  name: "LoaderComponent",
};
</script>

<style lang="scss" scoped>
@import "../styles/components/loaderComponent.scss";
</style>

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  library,
  type IconDefinition,
} from "@fortawesome/fontawesome-svg-core";
import {
  faUserCircle,
  faChartSimple,
  faMap,
  faMapPin,
  faCaretDown,
  faCaretUp,
  faArrowLeft,
  faArrowRight,
  faChevronLeft,
  faChevronRight,
  faList,
  faTableColumns,
  faTriangleExclamation,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faUserCircle as IconDefinition,
  faChartSimple as IconDefinition,
  faMap as IconDefinition,
  faMapPin as IconDefinition,
  faCaretDown as IconDefinition,
  faCaretUp as IconDefinition,
  faArrowLeft as IconDefinition,
  faArrowRight as IconDefinition,
  faChevronRight as IconDefinition,
  faChevronLeft as IconDefinition,
  faList as IconDefinition,
  faTableColumns as IconDefinition,
  faTriangleExclamation as IconDefinition,
  faRightFromBracket as IconDefinition
);

export default FontAwesomeIcon;

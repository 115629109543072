import type Device from "./Device";
import type Alert from "./Alert";

export default class State {
  session: { id_token: string; access_token: string };
  devices: Device[];
  alertRules: Alert[];
  alertEvents: Alert[];

  constructor() {
    this.session = { id_token: "", access_token: "" };
    this.devices = [];
    this.alertRules = [];
    this.alertEvents = [];
  }
}

import axios from "axios";
import "moment-timezone";
import { signOut } from "./Utils";

async function getAuthorizationHeader() {
  const parts = document.cookie.split("id_token=");
  let id_token: string | undefined;

  if (parts.length === 2) {
    id_token = parts.pop()?.split(";").shift();
  } else {
    signOut();
    throw new Error("Unauthorized");
  }

  return {
    headers: {
      Authorization: "Bearer " + id_token ? id_token : "",
    },
  };
}

export default {
  /**
   * Return list of devices
   */
  async getAllDevices() {
    const requestHeaders = await getAuthorizationHeader();

    const response = await axios.get(
      process.env.VUE_APP_API_URL + "device",
      requestHeaders
    );

    return response.data.devices;
  },

  /**
   * Return list of alerts
   */
  async getAllAlertRules(filter: any = null) {
    let payload = {};
    try {
      payload = await getAuthorizationHeader();
    } catch (err) {
      console.error(err);
    }

    const payloadParams: any = {
      params: {},
    };

    if (filter) payloadParams.params.filterRule = filter;
    Object.assign(payload, payloadParams);

    const response = await axios.get(
      process.env.VUE_APP_API_URL + "alert-rule",
      payload
    );
    return response.data.codes;
  },

  /**
   * Return list of alerts
   */
  async getAllAlertEvents(
    pagination: any,
    searchPlace: string,
    searchDevice: string,
    searchAlert: string,
    state: string,
    timeRange: any
  ) {
    const requestHeaders = await getAuthorizationHeader();

    const params = {
      ...requestHeaders,
      params: {
        pagination: pagination ? JSON.stringify(pagination) : "",
        filterPlace: searchPlace,
        filterDevice: searchDevice,
        filterAlert: searchAlert,
        state: state,
        timeRange: JSON.stringify(timeRange),
      },
    };

    const response = await axios.get(
      process.env.VUE_APP_API_URL + "alert-event",
      params
    );

    return response.data;
  },

  /**
   * Return top of alert events frequency
   */
  async getAlertEventsFrequency(period: string) {
    const requestHeaders = await getAuthorizationHeader();

    const params = {
      ...requestHeaders,
      params: {
        period: period,
      },
    };

    const response = await axios.get(
      process.env.VUE_APP_API_URL + "statistic/alert-event-frequency",
      params
    );

    return response.data;
  },

  /**
   * Return top of devices with count of alert events
   */
  async getAlertEventsDeviceCount(period: string) {
    const requestHeaders = await getAuthorizationHeader();

    const params = {
      ...requestHeaders,
      params: {
        period: period,
      },
    };

    const response = await axios.get(
      process.env.VUE_APP_API_URL + "statistic/alert-event-device-count",
      params
    );

    return response.data;
  },

  /**
   * Return thing from thingName
   */
  async getThing(thingName: string) {
    return axios.get(process.env.VUE_APP_API_URL + "iot/things/" + thingName);
  },
};

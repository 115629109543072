import { Auth } from "aws-amplify";
import store from "../store";

async function signOut() {
  // --- Suppression des tokens dans le store
  await store.dispatch("setSession", { access_token: "", id_token: "" });

  // --- Suppression des cookies
  document.cookie = `access_token=;expires=Thu, 01 Jan 1970 00:00:00 UTC`;
  document.cookie = `id_token=;expires=Thu, 01 Jan 1970 00:00:00 UTC`;

  // --- Déconnection d'aws amplify
  await Auth.signOut();
}

export { signOut };

import type Device from "@/domain/state/Device";
import type Alert from "@/domain/state/Alert";

const actions = {
  // --- Set Session
  setSession(state: any, session: { id_token: string; access_token: string }) {
    state.commit("setSession", session);
  },

  // --- Set Devices
  setDevices(state: any, devices: Device[]) {
    state.commit("setDevices", devices);
  },

  // --- Set Alert rules
  setAlertRules(state: any, alerts: Alert[]) {
    state.commit("setAlertRules", alerts);
  },

  // --- Set Alert events
  setAlertEvents(state: any, alerts: Alert[]) {
    state.commit("setAlertEvents", alerts);
  },
};

export default actions;

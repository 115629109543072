<template>
  <div class="full-screen-map">
    <MapComponent />
  </div>
</template>

<script>
import MapComponent from "../components/map/MapComponent";

export default {
  name: "Map",
  components: {
    MapComponent,
  },
};
</script>

<style lang="scss">
@import "../styles/views/map.scss";
</style>

<template>
  <div class="menu">
    <div class="menu-content">
      <div class="menu-actions">
        <!-- LOGO BUTTON-->
        <div class="icon menu-button"></div>

        <!-- MAP BUTTON-->
        <div
          class="button-wrapper"
          v-bind:class="{ selected: currentRouteName === 'Map' }"
          v-on:click="changePage('map')"
        >
          <div class="icon map-button">
            <font-awesome-icon :icon="['fas', 'map']" fixed-width />
          </div>
        </div>

        <!-- CONTROL BUTTON-->
        <div
          class="button-wrapper"
          v-bind:class="{ selected: currentRouteName === 'control' }"
          v-on:click="changePage('control')"
        >
          <div class="icon control-button">
            <font-awesome-icon :icon="['fas', 'chart-simple']" fixed-width />
          </div>
        </div>

        <!-- DICO ALERTS BUTTON-->
        <div
          class="button-wrapper"
          v-bind:class="{ selected: currentRouteName === 'dico-alerts' }"
          v-on:click="changePage('dico-alerts')"
        >
          <div class="icon control-button">
            <font-awesome-icon :icon="['fas', 'list']" fixed-width />
          </div>
        </div>
      </div>

      <!-- LOGOUT BUTTON-->
      <div class="button-wrapper logout" @click="signOutUser">
        <div class="icon menu-account-button">
          <font-awesome-icon :icon="['fas', 'right-from-bracket']" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FontAwesomeIcon from "@/assets/icons/fontawesome-icons";
import { signOut } from "@/service/Utils";

import Vue from "vue";
export default Vue.extend({
  name: "MenuComponent",
  data() {
    return {};
  },
  components: {
    FontAwesomeIcon,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    changePage(page) {
      if (this.currentRouteName !== page) {
        this.$router.push(`/${page}`);
      }
    },
    signOutUser() {
      signOut();
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/styles/components/menuComponent";
</style>

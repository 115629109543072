export enum DateFormat {
  LITTERAL = "DD MMMM YYYY",
  SMALL = "DD-MM-YYYY HH:mm",
  FULL = "DD MMMM yyyy HH:mm:ss",
}

export const Locale = "fr";

export const DaysOfWeek = {
  fr: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"],
  en: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
};

export const MonthsOfYear = {
  fr: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Aout",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
  en: [
    "January",
    "February",
    "March ",
    "April",
    "May ",
    "June",
    "July ",
    "August",
    "September ",
    "October",
    "November ",
    "December",
  ],
};

export enum RangeAction {
  PREVIOUS,
  NEXT,
}

export enum AlertEventState {
  ALL = "all",
  ONGOING = "ongoing",
}

export enum Period {
  YEAR = "year",
  MONTH = "month",
  WEEK = "week",
}

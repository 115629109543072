import type State from "@/domain/state/State";
import type Device from "@/domain/state/Device";
import type Alert from "@/domain/state/Alert";

const mutations = {
  // --- Set session
  setSession(
    state: State,
    session: { id_token: string; access_token: string }
  ) {
    state.session = session;
  },

  // --- Set Devices to store
  setDevices(state: State, devices: Device[]) {
    state.devices = devices;
  },

  // --- Set Alert rules to store
  setAlertRules(state: State, alertRules: Alert[]) {
    state.alertRules = alertRules;
  },

  // --- Set Alert events to store
  setAlertEvents(state: State, alertEvents: Alert[]) {
    state.alertEvents = alertEvents;
  },
};

export default mutations;

<template>
  <div id="alertsApp">
    <notifications position="bottom right" ignoreDuplicates />
    <template v-if="!signedIn">
      <amplify-authenticator>
        <amplify-sign-in slot="sign-in" hide-sign-up="true"></amplify-sign-in>
      </amplify-authenticator>
    </template>
    <template v-if="signedIn">
      <MenuComponent />
      <router-view />
    </template>
  </div>
</template>

<script>
import { Auth, Hub } from "aws-amplify";
import Vue from "vue";
import MenuComponent from "./components/menu/MenuComponent";
import { Translations } from "@aws-amplify/ui-components";
import { I18n } from "aws-amplify";
import AmplifyI18n from "amplify-i18n";
import { signOut } from "./service/Utils";

export default Vue.extend({
  name: "App",
  data() {
    return {
      loading: true,
    };
  },
  components: {
    MenuComponent,
  },
  computed: {
    signedIn() {
      if (this.$store.getters.session) {
        return (
          this.$store.getters.session.access_token !== "" ||
          this.$store.getters.session.id_token !== ""
        );
      } else {
        return false;
      }
    },
  },

  async beforeCreate() {
    await Auth.currentAuthenticatedUser()
      .then(async () => {
        if (!document.cookie) {
          throw new Error("Unauthorized");
        }

        let parts = document.cookie.split("access_token=");
        if (parts.length === 2) {
          let access_token = parts.pop().split(";").shift();
          parts = document.cookie.split("id_token=");
          let id_token = parts.pop().split(";").shift();

          this.$store.dispatch("setSession", {
            access_token: access_token,
            id_token: id_token,
          });
        }
        this.loading = false;
      })
      .catch((err) => {
        this.loading = false;

        if (
          (err.response && err.response.status === 401) ||
          err.message === "Unauthorized"
        ) {
          signOut();
        }
      });

    const listener = (data) => {
      switch (data.payload.event) {
        case "signIn":
          this.registerTokens(data.payload.data.signInUserSession);
          break;
      }
    };

    Hub.listen("auth", listener);
    I18n.setLanguage("fr");
  },
  mounted() {
    const locales = ["en", "fr", "de"];
    AmplifyI18n.configure(locales);
    I18n.setLanguage("fr");
    I18n.putVocabulariesForLanguage("fr", {
      [Translations.SIGN_UP_HAVE_ACCOUNT_TEXT]: "Avez-vous déjà un compte ?",
    });
  },
  methods: {
    registerTokens(session) {
      const access_token = session.accessToken.jwtToken;
      const id_token = session.idToken.jwtToken;

      const timestamp = new Date().getTime();
      const exp = timestamp + 60 * 60 * 1000;

      document.cookie = `access_token=${access_token};expires=${exp};path=/;`;
      document.cookie = `id_token=${id_token};expires=${exp};path=/;`;

      this.$store.dispatch("setSession", {
        access_token: access_token,
        id_token: id_token,
      });
    },
  },
});
</script>

<style>
@import "@/styles/global.scss";
</style>

import type State from "@/domain/state/State";
import type Device from "@/domain/state/Device";
import type Alert from "@/domain/state/Alert";

const getters = {
  // --- Get session
  session(state: State): { id_token: string; access_token: string } {
    return state.session;
  },

  // --- Get Devices
  devices(state: State): Device[] {
    return state.devices;
  },

  // --- Get alert rules
  alertRules(state: State): Alert[] {
    return state.alertRules;
  },

  // --- Get alert events
  alertEvents(state: State): Alert[] {
    return state.alertEvents;
  },
};

export default getters;

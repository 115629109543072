<template>
  <div id="map-container">
    <div class="loader-container" v-if="loading">
      <LoaderComponent />
    </div>
    <l-map
      ref="myMap"
      :zoom="6"
      :center="[47, 2]"
      :maxZoom="18"
      :options="{ zoomControl: false }"
    >
      <l-tile-layer
        url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png"
        attribution="&copy; <a href='http://osm.org/copyrighte'>OpenStreetMap</a> contributors"
      />
      <v-marker-cluster></v-marker-cluster>
    </l-map>
  </div>
</template>

<script>
import { LMap, LTileLayer } from "vue2-leaflet";
import { toRaw } from "vue";
import L from "leaflet";
import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";
import "leaflet/dist/leaflet.css";
import "@/styles/components/map/MarkerCluster.css";
import "@/styles/components/map/MarkerCluster.Default.css";
import LoaderComponent from "../LoaderComponent";
import moment from "moment";
import { fr } from "../../i18n/lang/moment";
import { Locale, DateFormat, AlertEventState } from "@/service/Constants";
import deviceNoConnectionIcon from "@/assets/icons/markers/device-no-connection.png";
import deviceRunningIcon from "@/assets/icons/markers/device-running.png";
import deviceErrorIcon from "@/assets/icons/markers/device-error.png";
import RestClientService from "../../service/RestClientService";
import { signOut } from "@/service/Utils";

export default {
  name: "MapChart",
  components: {
    LMap,
    LTileLayer,
    "v-marker-cluster": Vue2LeafletMarkerCluster,
    LoaderComponent,
  },
  data() {
    return {
      map: undefined,
      startDate: moment().subtract(7, "days"),
      endDate: moment(),
      loading: true,
      devices: [],
      alertEventsOngoing: [],
      waiting: false,
      markersCluster: null,
    };
  },
  async mounted() {
    // --- Get All Alert Events
    await RestClientService.getAllAlertEvents(
      null,
      null,
      null,
      null,
      AlertEventState.ONGOING,
      null
    )
      .then((response) => {
        this.alertEventsOngoing = response.events;
      })
      .catch((err) => {
        console.log(err);
        this.$notify({
          title: "Erreur",
          type: "error",
          text: "Un problème est survenu",
        });
        this.loading = false;
        if (err.response.status === 401) {
          signOut();
        }
      });

    // --- Get Devices
    if (this.$store.getters.devices && this.$store.getters.devices.length) {
      this.devices = this.$store.getters.devices;
      await this.loadPositions();
      this.loading = false;
    } else {
      await RestClientService.getAllDevices()
        .then((response) => {
          this.$store.dispatch("setDevices", response);
          this.devices = response;
          this.loadPositions();
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.$notify({
            title: "Erreur",
            type: "error",
            text: "Un problème est survenu",
          });
          if (err.response.status === 401) {
            signOut();
          }
        });
    }
    this.waiting = true;
  },
  beforeCreate() {
    moment.updateLocale("fr", fr);
  },
  methods: {
    /**
     * Place les capteurs sur la map
     * @returns {Promise<void>}
     */
    async loadPositions() {
      this.markersCluster = new L.MarkerClusterGroup({
        maxClusterRadius: 10,
        iconCreateFunction: function (cluster) {
          const markers = cluster.getAllChildMarkers();
          const markersAlert = markers
            .map((marker) => marker.hasAlert)
            .filter((isAlert) => isAlert)
          return L.divIcon({ html: `<div>${markers.length}</div>`, className: markersAlert.length > 0 ? 'marker-cluster cluster-alert' : 'marker-cluster cluster-no-alert', iconSize: L.point(40, 40) });
        }
      });
      let positions = [];

      this.map = this.$refs.myMap.mapObject;
      L.control
        .zoom({
          position: "bottomright",
        })
        .addTo(this.map);

      this.devices.map((device) => {
        this.createPopup(device);
        positions.push({
          lat: device.inputs[0].location.latitude,
          lng: device.inputs[0].location.longitude,
        });
      });

      if (positions.length) {
        toRaw(this.map).fitBounds(new L.LatLngBounds(positions));
      }
      toRaw(this.map).addLayer(this.markersCluster);
      this.loading = false;
    },

    /**
     * Rafraichir la map et ses layers
     * @returns {Promise<void>}
     */
    async refreshMap() {
      const positions = [];

      this.markersCluster.clearLayers();

      this.devices.map((device) => {
        this.createPopup(device);
        positions.push({
          lat: device.inputs[0].location.latitude,
          lng: device.inputs[0].location.longitude,
        });
      });

      if (positions.length) {
        toRaw(this.map).fitBounds(new L.LatLngBounds(positions));
      }

      toRaw(this.map).addLayer(this.markersCluster);
      this.loading = false;
    },

    /**
     * Visualisation des informations capteurs et de leurs inputs
     * @param device
     */
    createPopup(device) {
      // specify popup options
      const customOptions = {
        className: "map-popup",
      };

      let description = "";

      description += '<div class="popup-alert">';
      description += `<div class="popup-device-name">${this.getDeviceName(
        device
      )}</div>`;
      description +=
        '<div class="popup-container"><div class="popup-input-header"><span>Entrées</span><span>Status</span><span>Date de l\'alerte</span></div>';
      device.inputs.forEach((input) => {
        description += '<div class="popup-input-info">';
        description += `<span class="input-name">${
          input.thingAlias && input.thingAlias.length
            ? input.thingAlias
            : input.thingName
        }</span>`;
        let activeAlerts = this.getDeviceInputAlertActive(input);
        description += '<div class="alert-status-container">';
        if (activeAlerts && activeAlerts.length) {
          activeAlerts.map((activeAlert) => {
            description += '<div class="alert-content">';
            description += `<span title="${activeAlert.description}" class="alert-status code-ongoing">${activeAlert.code}</span>`;
            description += `<span class="alert-date">${moment(
              activeAlert.eventDate
            )
              .locale(Locale)
              .format(DateFormat.FULL)}</span>`;
            description += "</div>";
          });
        } else {
          description += '<div class="alert-content">';
          description += '<span class="alert-status">Aucune alerte</span>';
          description += '<span class="alert-date">-</span>';
          description += "</div>";
        }
        description += "</div>";
        description += "</div>";
      });
      description += '<div class="last-device-report">';
      description += `<span>dernière communication : ${this.getDeviceLastReport(
        device.inputs
      )}</span>`;
      description += "</div>";
      description += `</div>`;

      const position = new L.LatLng(
        device.inputs[0].location.latitude,
        device.inputs[0].location.longitude
      );

      let marker;

      const deviceIcon = L.icon({
        iconUrl: this.getDeviceIcon(device),
        iconSize: [15, 15],
      });

      marker = L.marker(position, {
        icon: deviceIcon,
      });

      marker.hasAlert = this.hasAlert(device);

      marker.bindPopup(description, customOptions);

      this.markersCluster.addLayer(marker);
    },
    getDeviceLastReport(inputs) {
      const date = moment(
        Math.max(...inputs.map((input) => moment(input.dateLastValueReported)))
      )

      return date.isValid()
        ? date.locale(Locale).format(DateFormat.FULL)
        : '--';
    },
    /**
     * vérifie si le device a un alias
     * @param device
     * @returns {any}
     */
    getDeviceName(device) {
      return device.alias ?? device.name;
    },
    /**
     * Récupère l'alerte active de l'entrée du capteur
     * @param input
     * @returns {*} Alerte
     */
    getDeviceInputAlertActive(input) {
      return this.alertEventsOngoing.filter((alertEventOngoing) =>
        alertEventOngoing.thingName.includes(input.thingName)
      );
    },
    /**
     * Retourne l'icône s'il y a une alerte dans les inputs du capteur
     * @param device
     * @returns {*}
     */
    getDeviceIcon(device) {
      let iconResult = deviceRunningIcon;
      if (this.hasAlert(device)) {
        iconResult = deviceErrorIcon;
      }
      return iconResult;
    },
    /**
     * Vérifie si un device à une alerte active
     * @param device
     */
    hasAlert(device) {
      let hasAlert = false;
      device.inputs.forEach((input) => {
          if (this.getDeviceInputAlertActive(input).length) {
            hasAlert = true;
          }
      });
      return hasAlert;
    },
    /**
     * Préparation de la mise à jour des données
     */
    prepareUpdateData() {
      new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, process.env.VUE_APP_CALL_API_TIME * 60 * 1000);
      }).then(async () => {
        this.waiting = false;
        this.loading = true;
        // --- Get All Alert Events
        await RestClientService.getAllAlertEvents(
          null,
          null,
          null,
          null,
          AlertEventState.ONGOING,
          null
        )
          .then((response) => {
            this.alertEventsOngoing = response.events;
            this.refreshMap();
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
            this.$notify({
              title: "Erreur",
              type: "error",
              text: "Un problème est survenu",
            });
            this.loading = false;
            if (err.response.status === 401) {
              signOut();
            }
          });
        this.waiting = true;
      });
    },
  },
  watch: {
    waiting() {
      if (this.waiting) {
        this.prepareUpdateData();
      }
    },
  },
};
</script>
